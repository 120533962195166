import quotesheader from './assets/quotes.png'
import ReactGA from 'react-ga4'
import preorder from './assets/preorder.png'
import glamshotPs5Limited from './assets/ps5-glamshot-limited.jpeg'

import { useEffect, useState } from 'react'
export const AskFans = () => {
    const [modal, setModal] = useState(false)
    const [showButton, setShowButton] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        })
    }, [])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    const [data, setData] = useState([
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    I'd say it was when <span>Adol first meets Dana proper</span>. Such great camera shots and
                    atmosphere.
                </p>
            ),
            platform: 'instagram',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    This game combined with it's music broke our hearts 😭. Favorite moment from the game is near the
                    ending <span>when adol and friends broke reality itself to see dana once again.</span>
                </p>
            ),
            platform: 'instagram',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    One of my favorite moments gotta be when <span>the heroes meet Dana.</span>
                </p>
            ),
            platform: 'facebook',
        },
        {
            hasSpoiler: false,
            quotes: "Oh god, the moment when the realization of Dana's purpose and the connection to a certain character. Ugh, so many, but I don't want to spoil it for people who have not played it.",
            platform: 'facebook',
        },
        {
            hasSpoiler: false,
            quotes: "Dogi's choice of... attire.",
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'Finding out just how much a dork Hummel is.',
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'Put it there, kid',
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: "Hummel slowly showing his cute side was one of my favourite things, he's great with kids and it warmed my heart",
            platform: 'twitter',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    My favorite moment is a spoiler, but I'll just say,{' '}
                    <span>the thing inside of the huge tree blew my mind wide open.</span>
                </p>
            ),
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'Highlights would be: The story from the second half on is amazing, the soundtrack as a whole, the way the cast flows really well (especially anything with Ricotta) and Dana’s story since she was my favorite character and had the best arc in the game brought me to tears more than once.',
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: "The story is my favorite part of the game. It's very interesting!",
            platform: 'twitter',
        },
        {
            spoiler: false,
            quotes: "Reaching the highest peak of Mount Gendarme and taking in the view of Seiren in it's entirety. Realizing there's still the 2nd half of the island to explore.",
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'How did you make Dana sound convicted yet conflicted, strong yet vulnerable ALL AT THE SAME TIME?!',
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: "My highlight is definitely the beginning. Waking up on that beach with Sunshine Coastline playing is a gaming experience that I'll never forget.",
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'Hummel as a whole.',
            platform: 'twitter',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    <span>Dana arriving to help for the first time in a raid.</span>
                </p>
            ),
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: "Sister Nia's side-quest.",
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'My highlight of Ys VIII is: the whole game (but the Oceanus battle is my fav)',
            platform: 'twitter',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    My favourite moments of Ys VIII had to be when all the castaways worked together to defeat the
                    Oceanus and <span>everyone paying their respects to Captain Barbaros.</span>
                </p>
            ),
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: "Gotta be the max approval scene for little Paro. He's such a fun character!",
            platform: 'twitter',
        },
        {
            hasSpoiler: true,
            quotes: (
                <p className='quotes'>
                    The first time you get a flashback of Dana creates a very good sense of mystery.{' '}
                    <span>Finding Dana in the present is also very special.</span>
                </p>
            ),
            platform: 'twitter',
        },
        {
            hasSpoiler: false,
            quotes: 'The Pangaia Plains camping scene. That was the moment the party felt like a family. Giving Ricotta the siblings and weird uncle she never had got me bawling my eyes out.',
            platform: 'twitter',
        },
    ])
    const handleClick = (i) => {
        const newData = data.map((it, index) => {
            if (i === index) return { ...it, showSpoiler: !it.showSpoiler }
            return it
        })
        setData(newData)
    }
    return (
        <>
            <img className='flex row fit' src={quotesheader} alt='ask the fans favorite quotes' />
            <div
                style={{
                    backgroundColor: '#4c7db5',
                    width: '100vw',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}
            >
                <i style={{ color: 'white' }} className='fa fa-long-arrow-left'></i>
                <a
                    href='/'
                    style={{
                        fontSize: '1.6rem',
                        fontWeight: '500',
                        color: 'white',
                        textDecoration: 'none',
                        marginLeft: '50px',
                    }}
                >
                    &#60; Go Back
                </a>
            </div>
            <p style={{ textAlign: 'center', marginTop: '2em', marginBottom: '2em' }}>
                Here are some fan favorite moments from Ys VIII: Lacrimosa of DANA! (Click on the black text to reveal
                spoiler)
            </p>
            <div className='quotes-masonry'>
                {data.map((it, i) => (
                    <>
                        <div key={it.index} id='quotes'>
                            {it.hasSpoiler ? (
                                <div
                                    className={it.showSpoiler ? `showspoiler` : `hidespoiler`}
                                    onClick={() => {
                                        handleClick(i)
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'spoilerclick',
                                            category: 'quotes',
                                            label: `spoiler`,
                                        })
                                    }}
                                >
                                    {it.quotes}
                                </div>
                            ) : (
                                <p className='quotes'>{it.quotes}</p>
                            )}
                            <div className='border' />{' '}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    marginTop: '20px',
                                }}
                            >
                                <i className={`fab fa-${it.platform} fa-2x md:fa-5x btn`} />
                                <p className='quotes-user'>{it.platform} User</p>
                            </div>
                        </div>
                    </>
                ))}
                {showButton && (
                    <button id='scrolltotop' onClick={scrollToTop} className='scrolltotop'>
                        <i className='fa fa-angle-up' />
                    </button>
                )}
            </div>{' '}
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '300px',
                    justifyContent: 'center',
                }}
                className='gradient'
            >
                <a
                    onClick={() =>
                        ReactGA.event({
                            action: 'click',
                            event: 'link',
                            category: 'preorder',
                            label: `NISA store`,
                        })
                    }
                    href='https://store.nisamerica.com/ys-viii/ys-viii-lacrimosa-of-dana-limited-edition-ps5'
                    style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer' }}
                >
                    <img onClick={(e) => setModal(true)} src={preorder} alt='preorder button' width={'90%'} />
                </a>

                <p style={{ color: 'white', marginLeft: 'auto', marginRight: 'auto', fontWeight: '400' }}>
                    Available November 15, preorder today!
                </p>
            </div>
        </>
    )
}
