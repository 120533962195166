import { useState } from "react"
import pegi from './assets/pegi-12.jpg'


export const Footer = ({ lang }) => {
   
    const MailChimp = () => {
        return (
            <form
                action='https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&id=349f7d20f9'
                method='post'
                id='mc-embedded-subscribe-form'
                name='mc-embedded-subscribe-form'
                className='flex row wrap'
                target='_blank'
                noValidate
            >
                <div className='flex row'>
                    <input
                        type='email'
                        name='EMAIL'
                        className='email'
                        id='mce-EMAIL'
                        placeholder={lang === 'en' ? 'email address' : 'adresse email'}
                        required
                        defaultValue=''
                        style={{
                            width: '150px',
                            height: '32px',
                            borderRadius: '5px',
                            border: '1px solid white',
                            fontSize: '16px',
                        }}
                    />

                    <button
                        type='submit'
                        value='Subscribe'
                        name='subscribe'
                        id='mc-embedded-subscribe'
                        className='sub-btn track-link'
                        data-label='Subscribe'
                        style={{ borderRadius: '2px', textAlign: 'center', margin: 16 }}
                    >
                        {lang === 'en' ? 'Subscribe to Newsletter!' : 'S’abonner à la Newsletter !'}
                    </button>
                </div>
            </form>
        )
    }

    return (
        <footer className='flex fit'>
            <MailChimp lang={lang} />
            <div className='logos flex row wrap'>
                {lang === 'en' ? (
                    <img src={'https://nisamerica.com/public/assets/logo/esrb-t.svg'} alt='' width={180} />
                ) : (
                    <img src={pegi} alt='' width={80} />
                )}

                <img src='https://nisamerica.com/public/assets/logo/ps5-ps.svg' alt='' width={180} />

                {/* <img
          src="https://nisamerica.com/public/assets/logo/ps4.svg"
          alt=""
          width={100}
        />
        <img
          src="https://nisamerica.com/public/assets/logo/psv.svg"
          alt=""
          width={135}
        />
        <img
          src="https://nisamerica.com/public/assets/logo/steam.svg"
          alt=""
          width={150}
        /> */}
                <img src='https://nisamerica.com/public/assets/logo/falcom-white.png' alt='' width={130} />
                <img src='https://nisamerica.com/public/assets/logo/nisa.webp' alt='' width={100} />
            </div>
            <p>© Nihon Falcom Corporation. All Rights Reserved.</p>
            <p>Licensed to and published by NIS America, Inc.</p>
            <p>
                "<span className='ps'>1</span>", "<span className='ps'>5</span>", and "PS5" are registered trademarks or
                trademarks of Sony Interactive Entertainment Inc.
            </p>
            <p>The rating icon is a trademark of the Entertainment Software Association.</p>
            <p>All other trademarks are the properties of their respective owners.</p>
        </footer>
    )
}
