import bg from './assets/bg.jpg'
import logo from './assets/logo.png'
import man from './assets/man.png'
import woman from './assets/woman.png'
import coming from './assets/coming.png'
import catchCopy from './assets/catch.png'
import catchCopyM from './assets/catch-mobile.png'
import learn from './assets/learn.png'
import preorder from './assets/preorder.png'
import quotesbutton from './assets/quotes-button.png'
//fr
import preorderFr from './assets/preorder-fr.png'
import comingFr from './assets/coming-fr.png'
import catchCopyFr from './assets/catch-fr.png'
import catchCopyMFr from './assets/catch-mobile-fr.png'
import learnFr from './assets/learn-fr.png'

import glamshotPs5Limited from './assets/ps5-glamshot-limited.jpeg'
import glamshotPs5Deluxe from './assets/ps5-glamshot-deluxe.jpeg'
import glamshotPs5DeluxeFr from './assets/ps5-glamshot-deluxe-fr.jpeg'
import glamshotPs5LimitedFr from './assets/ps5-glamshot-limited-fr.jpeg'

// import "animate.css";

import ReactPlayer from 'react-player/youtube'
import ReactGA from 'react-ga4'

import menu01 from './assets/menu01.png'
import menu02 from './assets/menu02.png'
import menu03 from './assets/menu03.png'
import menu04 from './assets/menu04.png'
import menu05 from './assets/menu05.png'
import menu06 from './assets/menu06.png'
import menu07 from './assets/menu07.png'

import wallpaper from './assets/wallpaper.jpg'
import wallpaperSmall from './assets/wallpapermobile.jpg'
import download from './assets/download.png'
import wallpaperMobile from './assets/Ys-Dana-Wallpaper-1080x1920.jpg'
import wallpaper2560 from './assets/Ys-Dana-Wallpaper-2560x1600.jpg'
import wallpaper3840 from './assets/Ys-Dana-Wallpaper-3840x2160.jpg'

import novelbanner from './assets/novelbanner.jpg'

import './App.css'
import { useEffect, useRef, useState } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap, Power0, Power1 } from 'gsap'
import { Power3 } from 'gsap/src/all'
import { Link } from 'react-router-dom'

const dic = {
    catchCopy: { en: 'The ultimate action RPG sets sail for PS5™', fr: '' },
    synopsis: {
        en: (
            <>
                <i>Ys</i> returns with an exciting adventure for the first time on PS5™! Adol awakens shipwrecked and
                stranded on a cursed island. There, he and the other shipwrecked passengers he rescues form a village to
                challenge fearsome beasts and mysterious ruins on an isolated island. Amidst this, Adol begins to dream
                of a mysterious blue-haired maiden living in a curiously advanced civilization. Join Adol as he unravels
                the enigma of the cursed isle and the blue-haired maiden Dana in <i>Ys VIII: Lacrimosa of DANA</i>!
            </>
        ),
        fr: (
            <>
                <i>Ys</i> fait son retour dans une aventure palpitante pour la première fois sur PlayStation 5 ! Adol se
                réveille à la suite d'un naufrage, échoué sur une île maudite. Aux côtés des autres passagers qu'il va
                secourir, ils vont former un village afin de faire face aux terribles monstres et aux ruines
                mystérieuses de cette île désolée. Adol commence aussi à rêver d'une mystérieuse jeune femme aux cheveux
                bleus vivant dans une civilisation avancée. Rejoignez Adol dans sa quête pour percer les mystères de
                l'île maudite et de la dame aux cheveux bleus, dans <i>YS VIII: Lacrimosa of Dana</i> !
            </>
        ),
    },
    specs: {},
}

const duration = 8

function Main({ lang, setLang }) {
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {}, [])
    return (
        <div id='app' className='flex fit'>
            <div id='lang' className='flex'>
                <p style={{ borderBottom: lang === 'en' ? 'solid 1px white' : null }} onClick={() => setLang('en')}>
                    EN
                </p>
                <p style={{ borderBottom: lang === 'fr' ? 'solid 1px white' : null }} onClick={() => setLang('fr')}>
                    FR
                </p>
            </div>
            <Header lang={lang} />
            <Content lang={lang} />

            <a href='https://ysviii.com/about.html' style={{ width: '70%', maxWidth: 400, margin: '64px 0' }}>
                <img
                    src={lang === 'en' ? learn : learnFr}
                    width='100%'
                    alt='learn more'
                    className='animate__animated animate__pulse animate__infinite'
                />
            </a>
        </div>
    )
}

export default Main

const Header = ({ lang, setLang }) => {
    const [modal, setModal] = useState(false)
    const [hover, setHover] = useState('first')

    const [edition, setEdition] = useState('limited')

    let bgRef = useRef(null)
    let h1Ref = useRef(null)
    let comingRef = useRef(null)
    let logoRef = useRef(null)
    let manRef = useRef(null)
    let womanRef = useRef(null)
    let headerRef = useRef(null)
    let quotesRef = useRef(null)

    const store = {
        en: {
            ps5: {
                limited: {
                    store: 'NISA ONLINE',
                    url: 'https://store.nisamerica.com/ys-viii/ys-viii-lacrimosa-of-dana-limited-edition-ps5',
                    img: glamshotPs5Limited,
                },
                deluxe: {
                    store: 'BestBuy',
                    url: 'https://www.bestbuy.com/site/ys-viii-lacrimosa-of-dana-deluxe-edition-playstation-5/6505750.p?skuId=6505750',
                    img: glamshotPs5Deluxe,
                },
            },
        },
        fr: {
            ps5: {
                limited: {
                    store: 'NISA Europe Online Store',
                    url: 'https://store.nisaeurope.com/products/viii-lacrimosa-of-dana-limited-edition-ps5',
                    img: glamshotPs5LimitedFr,
                },
                deluxe: {
                    store: 'NISA Europe Online Store',
                    url: 'https://store.nisaeurope.com/collections/frontpage-clean/products/copy-of-viii-lacrimosa-of-dana-deluxe-edition-ps5',
                    img: glamshotPs5DeluxeFr,
                },
            },
        },
    }

    useEffect(() => {
        gsap.fromTo(
            bgRef,
            { scale: 1.5, y: 0, opacity: 1, filter: 'blur(30px)' },
            {
                scale: 1,
                y: 0,
                opacity: 1,
                filter: 'blur(2px)',
                duration: duration + 1,
                delay: 0,
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            logoRef,
            { scale: 2, y: 200 },
            {
                scale: 1,
                y: 0,
                duration: duration,
                delay: 0,
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            manRef,
            { scale: 1.5, y: 0, x: '-100%', opacity: 1 },
            {
                scale: 1,
                y: 0,
                x: 0,
                opacity: 1,
                duration: duration,
                delay: 1,
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            womanRef,
            { scale: 1.5, y: 0, x: '150%' },
            {
                scale: 1,
                y: 0,
                x: 0,
                duration: duration,
                delay: 1.3,
                ease: Power3.easeOut,
            }
        )

        gsap.fromTo(h1Ref, { opacity: 0 }, { opacity: 1, duration: 1, delay: 6 })
        // gsap.fromTo(comingRef, { opacity: 0 }, { opacity: 1, duration: 1, delay: 7 })
        gsap.fromTo(quotesRef, { opacity: 0 }, { opacity: 1, duration: 1, delay: 7 })
        // gsap.fromTo(
        //   bgRef,
        //   { filter: "blur(2px)" },
        //   {
        //     filter: "blur(30px)",
        //     scrollTrigger: {
        //       trigger: headerRef,
        //       scrub: true,
        //     },
        //   }
        // );
    }, [])

    useEffect(() => {
        console.log('hovered')
        if (hover === 'first') {
            return
        } else {
            if (hover) {
                gsap.fromTo(
                    bgRef,
                    { scale: 1, y: 0, opacity: 1, filter: 'blur(2px)' },
                    {
                        scale: 1.1,
                        y: 0,
                        opacity: 1,
                        filter: 'blur(10px)',
                        duration: 0.3,
                        delay: 0,
                        ease: Power3.easeOut,
                    }
                )
            } else {
                gsap.fromTo(
                    bgRef,
                    { scale: 1.1, y: 0, opacity: 1, filter: 'blur(10px)' },
                    {
                        scale: 1,
                        y: 0,
                        opacity: 1,
                        filter: 'blur(2px)',
                        duration: 0.3,
                        delay: 0,
                        ease: Power3.easeOut,
                    }
                )
            }
        }
    }, [hover])

    return (
        <header className='flex row fit' ref={(el) => (headerRef = el)}>
            <div
                id='modal'
                className='flex'
                onClick={() => setModal(false)}
                style={{ display: modal ? 'flex' : 'none' }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 32,
                        color: 'white',
                        fontWeight: 'bolder',
                        fontSize: '2em',
                    }}
                >
                    X
                </div>
                <div id='modal-content' className='flex' onClick={(e) => e.stopPropagation()}>
                    <h1>PS5™</h1>
                    <p>
                        Edition:{' '}
                        <span
                            onClick={() => setEdition('limited')}
                            style={{
                                borderBottom: edition === 'limited' ? 'solid 1px white' : 'none',
                            }}
                        >
                            Limited
                        </span>
                        <span
                            onClick={() => setEdition('deluxe')}
                            style={{
                                borderBottom: edition === 'deluxe' ? 'solid 1px white' : 'none',
                            }}
                        >
                            Deluxe
                        </span>
                    </p>
                    <img
                        src={store[lang]['ps5'][edition].img}
                        alt=''
                        style={{
                            height: '60%',
                            maxHeight: 500,
                            maxWidth: '100%',
                            objectFit: 'contain',
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {edition === 'deluxe' && lang === 'en' ? (
                            <>
                                <a
                                    href='https://www.amazon.com/dp/B09X65FFQ7'
                                    rel='noreferrer'
                                    target={'_blank'}
                                    style={{ marginTop: 8, padding: 15 }}
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'link',
                                            category: 'ps5',
                                            label: `${store[lang]}, ${edition}`,
                                        })
                                    }
                                >
                                    <button>Amazon</button>
                                </a>{' '}
                                <a
                                    href='https://www.bestbuy.com/site/ys-viii-lacrimosa-of-dana-deluxe-edition-playstation-5/6505750.p?skuId=6505750'
                                    rel='noreferrer'
                                    target={'_blank'}
                                    style={{ marginTop: 8, padding: 15 }}
                                    onClick={ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'ps5',
                                        label: `${store[lang]}, ${edition}`,
                                    })}
                                >
                                    <button>BestBuy</button>
                                </a>{' '}
                                <a
                                    href='https://www.target.com/p/ys-viii-lacrimosa-of-dana-deluxe-edition-playstation-5/-/A-86461573'
                                    rel='noreferrer'
                                    target={'_blank'}
                                    style={{ marginTop: 8, padding: 15 }}
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'link',
                                            category: 'ps5',
                                            label: `${store[lang]}, ${edition}`,
                                        })
                                    }
                                >
                                    <button>Gamestop</button>
                                </a>{' '}
                                <a
                                    href='https://www.gamestop.ca/PS5/Games/895155/ys-viii-lacrimosa-of-dana-deluxe-edition-ps5'
                                    rel='noreferrer'
                                    target={'_blank'}
                                    style={{ marginTop: 8, padding: 15 }}
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'link',
                                            category: 'ps5',
                                            label: `${store[lang]}, ${edition}`,
                                        })
                                    }
                                >
                                    <button>Gamestop (Canada)</button>
                                </a>{' '}
                                <a
                                    href='https://www.target.com/p/ys-viii-lacrimosa-of-dana-deluxe-edition-playstation-5/-/A-86461573'
                                    rel='noreferrer'
                                    target={'_blank'}
                                    style={{ marginTop: 8, padding: 15 }}
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'link',
                                            category: 'ps5',
                                            label: `${store[lang]}, ${edition}`,
                                        })
                                    }
                                >
                                    <button>Target</button>
                                </a>
                            </>
                        ) : edition === 'limited' && lang === 'en' ? (
                            <a
                                href='https://store.nisamerica.com/ys-viii/ys-viii-lacrimosa-of-dana-limited-edition-ps5'
                                rel='noreferrer'
                                target={'_blank'}
                                style={{ marginTop: 8 }}
                                onClick={() =>
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'ps5',
                                        label: `${store[lang]}, ${edition}`,
                                    })
                                }
                            >
                                <button>{store[lang]['ps5'][edition].store}</button>
                            </a>
                        ) : edition === 'limited' && lang === 'fr' ? (
                            <a
                                href='https://store.nisaeurope.com/products/viii-lacrimosa-of-dana-limited-edition-ps5'
                                rel='noreferrer'
                                target={'_blank'}
                                style={{ marginTop: 8 }}
                                onClick={() =>
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'ps5',
                                        label: `${store[lang]}, ${edition}`,
                                    })
                                }
                            >
                                <button>{store[lang]['ps5'][edition].store}</button>{' '}
                            </a>
                        ) : (
                            <a
                                href='https://store.nisaeurope.com/collections/frontpage-clean/products/copy-of-viii-lacrimosa-of-dana-deluxe-edition-ps5'
                                rel='noreferrer'
                                target={'_blank'}
                                style={{ marginTop: 8 }}
                                onClick={() =>
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'ps5',
                                        label: `${store[lang]}, ${edition}`,
                                    })
                                }
                            >
                                <button>{store[lang]['ps5'][edition].store}</button>{' '}
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div id='bg' className='flex row fit' ref={(el) => (bgRef = el)}></div>
            <img src={man} alt='' className='char man' ref={(el) => (manRef = el)} />
            <img src={woman} alt='' className='char woman' ref={(el) => (womanRef = el)} />
            <div className='flex' ref={(el) => (logoRef = el)} id='logo'>
                <img
                    src={logo}
                    alt=''
                    width={'100%'}
                    // id="logo"
                    // ref={(el) => (logoRef = el)}
                />
                {/* <h1 className="zindex2" ref={(el) => (h1Ref = el)}> */}
                <div id='preorder' className='flex' ref={(el) => (h1Ref = el)} onClick={(e) => setModal(true)}>
                    <img src={lang === 'en' ? preorder : preorderFr} alt='' width={'100%'} />
                </div>
                {/* <img
                    src={lang === 'en' ? coming : comingFr}
                    alt=''
                    className=''
                    ref={(el) => (comingRef = el)}
                    style={{ width: '60%', maxWidth: 230, minWidth: 100 }}
                /> */}
                <div className='flex'>
                    <Link
                        to='/quotes'
                        onClick={() =>
                            ReactGA.event({
                                action: 'click',
                                event: 'link',
                                category: 'internal',
                                label: `quotes`,
                            })
                        }
                    >
                        <div
                            ref={(el) => (quotesRef = el)}
                            style={{ textAlign: 'center', margintTop: '0', width: '100%' }}
                        >
                            <img className='quotesbutton' src={lang === 'en' && quotesbutton} alt='' width={'60%'} />
                        </div>
                    </Link>
                </div>
            </div>
            {/* <button
        className="btn-box"
        // onMouseEnter={onHover}
        // onMouseLeave={onExit}
      >
        PREORDER
      </button> */}
        </header>
    )
}

const Content = ({ lang }) => {
    let copyRef = useRef(null)
    let copyRefM = useRef(null)
    let textRef = useRef(null)
    let dlRef = useRef(null)
    let novelref = useRef(null)
    let dlContentRef = useRef(null)
    let dlImgRef = useRef(null)
    let dlImgSmallRef = useRef(null)

    // let btn1Ref = useRef(null);
    // let btn2Ref = useRef(null);
    // let btn3Ref = useRef(null);
    // let btn4Ref = useRef(null);
    // let btn5Ref = useRef(null);
    // let btn6Ref = useRef(null);
    // let btn7Ref = useRef(null);

    const navbtns = [
        { img: menu01, url: 'https://ysviii.com/product.html' },
        { img: menu02, url: 'https://ysviii.com/prologue.html' },
        { img: menu03, url: 'https://ysviii.com/chara.html' },
        { img: menu04, url: 'https://ysviii.com/system.html' },
        { img: menu05, url: 'https://ysviii.com/battle.html' },
        { img: menu06, url: 'https://ysviii.com/trailers.html' },
        { img: menu07, url: 'https://ysviii.com/special.html' },
    ]

    useEffect(() => {
        const piece = 0.3
        const dur = 0.4
        const delay = 7
        const start = 400
        const yVar = -30

        const ease = 'bounce'

        gsap.fromTo(
            copyRef,
            { opacity: 0, x: -300 },
            {
                opacity: 1,
                x: 0,
                duration: 2,
                scrollTrigger: { trigger: copyRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )

        gsap.fromTo(
            copyRefM,
            { opacity: 0, x: -300 },
            {
                opacity: 1,
                x: 0,
                duration: 2,
                scrollTrigger: { trigger: copyRefM, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            textRef,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 2,
                delay: 0.5,
                scrollTrigger: { trigger: copyRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            dlRef,
            { opacity: 0, x: -300 },
            {
                opacity: 1,
                x: 0,
                duration: 2,

                scrollTrigger: { trigger: dlRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            dlImgRef,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,

                scrollTrigger: { trigger: dlRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
         
        gsap.fromTo(
            dlImgSmallRef,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,

                scrollTrigger: { trigger: novelref, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            dlContentRef,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                delay: 0.5,
                scrollTrigger: { trigger: dlImgRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
        gsap.fromTo(
            novelref,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,

                scrollTrigger: { trigger: dlContentRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
    }, [])

    return (
        <div className='flex'>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                <div style={{ textAlign: 'center' }} ref={(el) => (dlRef = el)}>
                    <img
                        style={{
                            width: '90%',
                            maxWidth: '1000px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '3rem',
                            marginBottom: '0.1vw',
                        }}
                        src={download}
                        alt='download wallpaper'
                    />{' '}
                    <p style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        We hope you enjoy this beautiful art of Dana made by{' '}
                        <span>
                            <a
                                style={{ textDecoration: 'none', fontWeight: 'bold' }}
                                href='https://twitter.com/moshimoshibe'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                @moshimoshibe
                            </a>
                        </span>
                        !
                    </p>
                </div>{' '}
                <img
                    style={{ width: '90%', maxWidth: '1000px', margin: 'auto' }}
                    src={wallpaper}
                    alt='wallpaper'
                    ref={(el) => (dlImgRef = el)}
                    className='hide-on-mobile'
                />{' '}
                <img
                    style={{ width: '60%', maxWidth: '500px', margin: 'auto' }}
                    src={wallpaperSmall}
                    alt='wallpaper'
                    ref={(el) => (dlImgSmallRef = el)}
                    className='hide-on-desktop'
                />
                <div className='dlbuttons' ref={(el) => (dlContentRef = el)}>
                    <a href={wallpaper3840} download>
                        <button
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'download',
                                    category: 'wallpaper',
                                    label: `3840`,
                                })
                            }}
                            className='dlbutton'
                        >
                            3840 x 2160
                        </button>
                    </a>{' '}
                    <a href={wallpaper2560} download>
                        <button
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'download',
                                    category: 'wallpaper',
                                    label: `2560`,
                                })
                            }}
                            className='dlbutton'
                        >
                            2560 x 1600
                        </button>
                    </a>
                    <a href={wallpaperMobile} download>
                        <button
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'download',
                                    category: 'wallpaper',
                                    label: `mobile`,
                                })
                            }}
                            className='dlbutton'
                        >
                            Mobile
                        </button>{' '}
                    </a>
                </div>
            </div>
            <div style={{ textAlign: 'center' }} ref={(el) => (novelref = el)}>
                <a className='novellink' href='https://lacrimosathenovel.com/'>
                    <img
                        style={{
                            width: '100%',
                            maxWidth: '1000px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '3rem',
                            marginBottom: '0.1vw',
                        }}
                        src={novelbanner}
                        alt='novel'
                    />{' '}
                </a>
            </div>
            <img
                src={lang === 'en' ? catchCopy : catchCopyFr}
                alt=''
                style={{ width: '90%', marginTop: 64 }}
                ref={(el) => (copyRef = el)}
                className='hide-on-mobile '
            />
            <img
                ref={(el) => (copyRefM = el)}
                src={lang === 'en' ? catchCopyM : catchCopyMFr}
                alt=''
                width={'90%'}
                className='hide-on-desktop '
                style={{ marginTop: 64 }}
            />
            <p ref={(el) => (textRef = el)}>{dic.synopsis[lang]}</p>
            {lang === 'en' && (
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/uq5Sl2rp1b4'
                        width='100%'
                        height='100%'
                    />
                </div>
            )}
            <div className='player-wrapper' style={{ marginTop: 10 }}>
                <ReactPlayer
                    className='react-player'
                    url={lang === 'en' ? 'https://youtu.be/RkeNP0KdOBk' : 'https://youtu.be/kNA0ktAYmIw'}
                    width='100%'
                    height='100%'
                />
            </div>
            <div className='player-wrapper' style={{ marginTop: 10 }}>
                <ReactPlayer
                    className='react-player'
                    url={lang === 'en' ? 'https://youtu.be/brhfKfwtNH0' : 'https://youtu.be/HTexbWEDQo0'}
                    width='100%'
                    height='100%'
                />
            </div>
            <Specs lang={lang} />
        </div>
    )
}

const Specs = ({ lang }) => {
    let refRef = useRef(null)

    useEffect(() => {
        gsap.fromTo(
            refRef,
            { opacity: 0, x: -300 },
            {
                opacity: 1,
                x: 0,
                duration: 2,
                scrollTrigger: { trigger: refRef, start: '-=500' },
                ease: Power3.easeOut,
            }
        )
    }, [])

    return (
        <div className='specs flex' ref={(el) => (refRef = el)}>
            <h1>{lang === 'en' ? 'GAME DETAILS' : 'INFOS'}</h1>
            <div className='flex row'>
                <div className='key'>{lang === 'en' ? 'Genre' : 'Genre'}</div>
                <div className='value'>Action RPG</div>
            </div>

            <div className='flex row'>
                <div className='key'>{lang === 'en' ? 'Players' : 'Nombre de joueurs'}</div>
                <div className='value'>1</div>
            </div>
            <div className='flex row'>
                <div className='key'>{lang === 'en' ? 'Developer/Licensor' : 'Développeur'}</div>
                <div className='value'>Nihon Falcom</div>
            </div>
            <div className='flex row'>
                <div className='key' style={{ textTransform: 'capitalize' }}>
                    {lang === 'en' ? 'Publisher' : 'éditeur'}
                </div>
                <div className='value'>NIS America</div>
            </div>
            <div className='flex row'>
                <div className='key'>{lang === 'en' ? 'Text Language' : 'Textes'}</div>
                <div className='value'>{lang === 'en' ? 'English, French' : 'Français, Anglais'}</div>
            </div>
            <div className='flex row'>
                <div className='key'>{lang === 'en' ? 'Audio Language' : 'Voix'}</div>
                <div className='value'>{lang === 'en' ? 'English, Japanese' : 'Anglais, Japonais'}</div>
            </div>
        </div>
    )
}
