import { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Footer } from './Footer'
import Analytics from './hooks/Analytics'

import Main from './Main'
import { AskFans } from './AskFans'


function App() {
    const [lang, setLang] = useState('en')

    return (
        <Router  forceRefresh>
            <Analytics />
            <Routes>
                <Route exact path='/' element={<Main lang={lang} setLang={setLang} />} />
                <Route path='quotes' element={<AskFans />} />
            </Routes>{' '}
            <Footer lang={lang} />
        </Router>
    )
}

export default App

