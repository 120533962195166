import ReactGA from 'react-ga4'
import { useEffect } from 'react'

const Analytics = () => {
    useEffect(() => {
        ReactGA.initialize([{ trackingId: 'G-TNV54YETY6' }, { trackingId: 'UA-92225218-1' }])
    })
   

    return null
}

export default Analytics
